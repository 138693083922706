import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Body } from '@components/styled/Typography';
import { ReactComponent as PolygonComponent } from '@icons/wolfkit-light/polygon-up.svg';
const PriceQuotationContainer = styled.div `
    display: flex;
    align-items: end;
    user-select: none;

    color: ${props => props.theme.palette.text.disabled};
`;
const PriceTrendContainer = styled.div `
    display: flex;
    align-items: center;
    line-height: 18px;
`;
const Text = styled(Body) `
    display: inline;
    font-size: 18px;
    line-height: 22px;
`;
const ValueToSellText = styled(Text) `
    margin-right: 4px;
`;
const PriceText = styled(Text) `
    margin-right: 4px;
`;
const Percentage = styled(Text) `
    font-size: 12px;
    line-height: 18px;
`;
const PolygonUp = styled(PolygonComponent) `
    color: ${props => props.theme.palette.text.disabled};
`;
const PolygonDown = styled(PolygonUp) `
    transform: rotateX(180deg);
`;
const renderPolygon = (trend) => {
    switch (trend) {
        case 'down':
            return _jsx(PolygonDown, {});
        case 'up':
            return _jsx(PolygonUp, {});
        default:
            return null;
    }
};
/**
 * component for displaying info as ratio
 */
const PriceQuotation = ({ valueToBy = undefined, valueToSell = undefined, price = undefined, percentage = undefined, trend = undefined, }) => (_jsxs(PriceQuotationContainer, { children: [_jsx(ValueToSellText, { children: valueToBy && valueToSell ? `${valueToBy || ''}/${valueToSell || ''}` : '' }), _jsx(PriceText, { children: price || '' }), _jsxs(PriceTrendContainer, { children: [renderPolygon(trend), _jsx(Percentage, { children: percentage ? `${percentage} %` : '' })] })] }));
export default PriceQuotation;
